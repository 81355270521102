import React from 'react'
import "./ImgSlider.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styled from "styled-components";

const ImgSlider = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
      };
    const Carousel = styled(Slider)`
        margin-top: 20px;
        li.slick-active button:before {
            color: white;
        }
        ul li button {
            &:before {
                font-size: 10px;
                color: rgb(150,158,171);
            }
        }
        .slick-list {
            overflow: visible;
        }
        button {
            z-index:1;
        }
    `

    const Wrap = styled.div`
    cursor: pointer;
    img{
        border: 4px solid transparent;
        width:100%;
        height: 100%;
        border-radius: 4px;
        box-shadow: rgb(0 0 0/ 69% ) 0px 26px 30px -10px,
        rgb(0 0 0 /73%) 0px 16px 10px -10px;
        transition-duration: 300ms;

        &:hover {
            border: 4px solid rgba(249,249,249, 0.8)
        }
    }
    
    `
  return (
   <Carousel {...settings}>
   <Wrap>
    <img src="/images/slider-badag.jpg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-badging.jpg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-scale.jpg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-scales.jpg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-dancingstars.jpeg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-loveNthunder.jpeg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-simpsons.jpeg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-werewolf.jpeg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-anchor.jpeg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-hocuspocus.jpeg"></img>
    </Wrap>
    <Wrap>
    <img src="/images/slider-shehulk.jpeg"></img>
    </Wrap>
    </Carousel>
  )
}

export default ImgSlider