import React, { useEffect, useState } from 'react';
import axios from '../../axios'
import styled from 'styled-components'
import "./Detail.css"
import {useLocation} from "react-router-dom"



const Detail = () => {
    const location = useLocation()
    console.log('hash', location.hash);
    console.log('pathname', location.pathname);
    console.log('search', location.search);
    const [movie,setMovie] = useState([])
    const Container = styled.div` 
        min-height:calc(100vh-70px);
        padding: 0 calc(3.5wv + 5px);
        position: relative;   
        margin-left:20px;
    `
    const PlayButton = styled.button`
        border-radius: 4px;
        font-size: 15px;
        display:flex;
        align-items: center;
        height: 56px;
        background: rgb(249,249,249);
        border: none;
        padding: 0px 24px;
        margin-right: 22px;
        letter-spacing: 1.8px;
        cursor: pointer;
        &:hover {
            background: rgb(198,198,198);
        }
    `
    const TrailerButton = styled(PlayButton)`
        background: rgb(0,0,0,0.3);
        border: 1px solid rgb(249,249,249);
        color: rgb(249,249,249);
        text-transform: uppercase;
    `
    const AddButton = styled.button`
        border-radius: 50%;
        cursor: pointer;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid white;
        background-color: rgba(0,0.0,0.6);
        margin-right: 16px;
        span {
            font-size: 30px;
            color: white;
        }
    `
    const GroupWatchButton = styled(AddButton)`
    
    background: rgb(0,0,0);
    `
    const SubTitle = styled.div`    
    color: rgb(249,249,249);
    font-size: 15px;
    min-height: 20px;
    margin-top: 26px;

    `
    const Description = styled.div` 
    color: rgb(249,249,249);
    font-size: 20px;
    width: 40%;
    margin-top: 16px;
    line-height: 1.6;   
   
    `

    const Controls = styled.div` 
        display:flex;
        align-items: center;      
    
    `

    const ImageTitle = styled.div` 
        height: 30vh;
        width: 35vw;
        min-height: 170px;
        min-width: 200px;
        font-size: 28px;

        img{
            width: 100%;
            height: 100%;
            object-fit:contain;
        }

    
    `
    const Background = styled.div`
        position: fixed;
        top:0;
        left:0;
        bottom:0;
        right:0;
        z-index:-1;
        opacity: 0.8;

        img {
            width:100%;
            height: 100%;
            object-fit:cover;
        }
   
`
const cloudfront_url = "https://dcblsqunc3gjs.cloudfront.net/disney"


useEffect(() => {

    let locationpath = location.pathname
    let path = locationpath.split("/")
    console.log("path0",path[0])
    console.log("path1",path[1])
    console.log("path2",path[2])
    console.log("path3",path[3])





    // make requests for rows
    async function fetchData(path) {
        let url = `/${path[2]}/${path[3]}`
        console.log("Url",url)
        const request = await axios.get(url)
        console.log("FetchData", request)
        setMovie(request.data)
        return request
    }
    fetchData(path);

}, [])

  return (
    <Container>
        <Background>
            <img src={`${movie ? cloudfront_url + movie.backdrop_path: ""}`} alt={`${movie ? movie.title : ""}`}/>

            </Background>
            <ImageTitle>
                <h1>{`${movie ? movie.title: ""}`}</h1>

            </ImageTitle>
            <Controls>
                <PlayButton>
                    <img src="/images/play-icon-black.png" />
                    <span>Play</span>
                    </PlayButton>
                <TrailerButton>
                <img src="/images/play-icon-white.png" />
                <span>Watch Trailer</span>
                </TrailerButton>
                <AddButton>
               <span>+</span>
                </AddButton>
                <GroupWatchButton>
                <img src="/images/group-icon.png" />
                </GroupWatchButton>
            </Controls>
            <SubTitle>
            {`${movie ? "Release Date: " + movie.release_date : ""}`}
            </SubTitle>
            <Description>
            {`${movie ? movie.overview : ""}`}
            </Description>

    </Container>
  )
}

export default Detail