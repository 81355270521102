const API_KEY = "ff5d04cf8afad69811f95a6788f5fc25"

const requests = {
    fetchStarWars: `/getmovies/1`,
    fetchDisney: `/getmovies/2`,
    fetchPixar: `/getmovies/3`,
    fetchMarvel: `/getmovies/420`,
    fetchNational: `/getmovies/7521`,
    fetchStarWarsTV: `/gettvs/1`,
    fetchDisneyTV: `/gettvs/2`,
    fetchPixarTV: `/gettvs/3`,
    fetchMarvelTV: `/gettvs/420`,
    fetchNationalTV: `/gettvs/7521`,
    fetchRecommend: `/recommend`,
    fetchRecommendTV: `/recommendtv`,
    fetchDocumentaries: `/discover/movie?api_key=${API_KEY}&with_genres=99`,

}

export default requests;