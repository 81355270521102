import React, { useEffect, useState } from 'react';
import axios from '../../axios'
import "./Row.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import {Link } from "react-router-dom"




const Row = ({ title, fetchUrl, isLargeRow,isMovie}) => {
    const [movies, setMovies] = useState([])
    const [trailerUrl, setTrailerUrl] = useState("");

    const base_url = "https://image.tmdb.org/t/p/original/"
    const cloudfront_url = "https://dcblsqunc3gjs.cloudfront.net/disney"

    const opts = {
        height: "390",
        width: "99%",
        playerVars: {
            autoplay: 0,
        }
    }

    const handleClick = (movie) => {
        // console.table(movie?.title)
        console.log("Click")

    }


    useEffect(() => {

        // make requests for rows
        async function fetchData() {
            const request = await axios.get(fetchUrl)
            console.log("FetchData", request)
            setMovies(request.data)
            return request
        }
        fetchData();

    }, [fetchUrl])

    const Wrap = styled.div`
        cursor:pointer;
        border-radius: 10px;
        overflow:hidden;
        border: 3px solid rgba(249,249, 249, 0.1);
        box-shadow: rgb(0 0 0/ 69% ) 0px 26px 30px -10px,
        rgb(0 0 0 /73%) 0px 16px 10px -10px;
        transition: all 250ms cubic-bezier(0.25,0.46,0.45,0.94) 0s;
        
        img {
            width:100%;
            height: 100%;
            object-fit:cover;
            
        }
       
        &:hover {
            transform: scale(1.05);
            border-color: rgba(249,249,249,0.8);
            box-shadow: rgb(0 0 0/ 80% ) 0px 40px 58px -16px,
            rgb(0 0 0 /73%) 0px 20px 22px -10px;
            
        }

    `
    const Carousel = styled(Slider)`
    margin-top: 20px;
    li.slick-active button:before {
        color: white;
    }
    ul li button {
        &:before {
            font-size: 30px;
            color: rgb(150,158,171);
        }
    }
    .slick-list {
        overflow: hidden;
     
      
    }
    button {
        z-index:1;
    }
  
      .slick-slide > div {
        margin: 0 10px;
      }
    `
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false
    };

    //console.log("Movies", movies)



    //https://image.tmdb.org/t/p/original/ymbuoBoL1i94xAOzVJF6IuWLfD.jpg
    return (
        <div className='row'>
            <h2>{title}</h2>
            <Carousel {...settings}>
                {movies.map(movie => (
                    <Wrap>
                        
                            <Link to={`/detail/${isMovie ? "movie/" : "tv/"}${movie.id}`}>
                        <img
                            key={movie.id}
                            onClick={() => handleClick(movie)}
                            className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                            src={`${cloudfront_url}${isLargeRow ? movie.poster_path : movie.backdrop_path}`}
                            alt={movie.title} />
                            </Link>
                            
                    </Wrap>
                ))}
            </Carousel>

            <div style={{ padding: "40px" }}>

            </div>
        </div>
    )
}

export default Row