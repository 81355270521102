import React from 'react'
import './Home.css'
import styled from "styled-components";
import ImgSlider from '../ImgSlider/ImgSlider';
import Viewers from '../Viewers/Viewers';
import Movies from '../Movies/Movies';
import requests from '../../requests';
import Row from '../Row/Row';

const Home = () => {
  const Container = styled.main`
    min-height: calc(100vh - 70px);
    padding: 0 calc(3.5vw + 5px);
    postion: relative;

    &:before {
      background: url("/images/home-background.png") center center / cover no-repeat fixed;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom:0;
      z-index: -1;
    }
    
    `
  return (
    <Container>
      <ImgSlider/> 
      <Viewers/>
      <Row title="Recommended for you" fetchUrl={requests.fetchRecommend} isMovie/>
     <Row title="TV Recommended" fetchUrl={requests.fetchRecommendTV}/>
     <Row title="Star Wars" fetchUrl={requests.fetchStarWars} isMovie/>
     <Row title="Marvel Featured" fetchUrl={requests.fetchMarvel} isMovie/>
     <Row title="Disney Featured" fetchUrl={requests.fetchDisney} isLargeRow isMovie/>
     <Row title="Pixar Featured" fetchUrl={requests.fetchPixar} isMovie/>
     <Row title="National Geographic Featured" fetchUrl={requests.fetchNational} isMovie/>
     <Row title="Star Wars TV" fetchUrl={requests.fetchStarWarsTV}/>
     <Row title="Pixar TV" fetchUrl={requests.fetchPixarTV}/>
     <Row title="Disney TV" fetchUrl={requests.fetchDisneyTV}/>
     <Row title="Marvel TV" fetchUrl={requests.fetchMarvelTV}/>
     <Row title="National Geograhic TV" fetchUrl={requests.fetchNationalTV}/>
     

      
      </Container>
  )
}

export default Home