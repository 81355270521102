import React from 'react';
import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import Detail from './Components/Detail/Detail';
import Login from './Components/Login/Login';
import {BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"



function App() {
  return (
    <div className="app">
       <Header/>
      <Routes>
      <Route
          path="/detail"
          element={
            <Detail/>          
            
          }
        />
         <Route
          path="/login"
          element={
            <Login/>          
            
          }
        />
     <Route
          path="/detail/tv/:id"
          element={
            <Detail/>          
            
          }
        />
      <Route
          path="/detail/movie/:id"
          element={
            <Detail/>          
            
          }
        />
    <Route
          path="/home"
          element={
            <Home/>          
            
          }
        />
      <Route
          path="/"
          element={
            <Home/>          
            
          }
        />
      </Routes>
     
    </div>
  );
}

export default App;
